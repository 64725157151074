<template>
  <Modal
    ref="modal"
    size="xl"
    title="Contacten samenvoegen"
    @hide="$emit('hide')"
  >
    <span slot="header">{{ headerText || 'Kies welke gegevens je wenst te behouden.' }}</span>
    <form class="form-horizontal row">
      <div
        v-if="errorMessage"
        class="tw-flex tw-justify-center"
      >
        <p class="tw-inline-block tw-rounded tw-bg-danger tw-text-white tw-font-bold tw-p-2 tw-m-0">
          {{ errorMessage }}
        </p>
      </div>
      <div v-else-if="contacts.length" :class="sizeClass">
        <h4 style="text-align: center;">Behouden Contact</h4>
        <contact-merge-field :ref=field.val
                             :contact="firstContact"
                             :disabled="false"
                             :field="field"
                             :showLabel="true"
                             :showSelectButton="false"
                             :key="index"
                             v-for="(field, index) in fields"></contact-merge-field>
      </div>
      <div :class="sizeClass" v-for="(contact, index) in trueContacts" :key="index">
        <h4 style="text-align: center">Contact {{ index + 1 }}</h4>
        <contact-merge-field @use-value="useValue"
                             :contact="contact"
                             :disabled="true"
                             :field="field"
                             :showLabel="false"
                             :showSelectButton="true"
                             :key="index"
                             v-for="(field, index) in fields"></contact-merge-field>
      </div>
    </form>
    <div v-if="!errorMessage" slot="footer">
      <button @click="doContactMerge"
              class="btn btn-primary"
              target="_blank"
              type="button">
        Samenvoegen
      </button>
      <button
        type="button"
        class="btn btn-white"
        @click="hideModal"
      >
        {{ automaticPopUp ? 'Deze stap overslaan' : 'Annuleren' }}
      </button>
    </div>
  </Modal>
</template>

<script>
import Vue from 'vue'
import $ from 'jquery'
import Modal from '../iam/Modal'
import { questionModal, startLoadingModal, successModal, errorModal } from '@/modalMessages'
import CityPicker from '../properties/CityPicker'
import DatePicker from '../iam/DatePicker'
import { mergeContacts, getMergeContactsInitialData } from '@/services/contacts'

Vue.component('contact-merge-textinput', {
  props: ['value'],
  template: '<input type="text" class="form-control" :value="value" ref="input" @input="input">',
  methods: {
    input () {
      this.$emit('input', this.$refs.input.value)
    }
  }
})

Vue.component('contact-merge-select', {
  props: ['value', 'options'],
  template: `
      <select :value="selected" class="form-control" ref="select" @change="onChange">
        <option v-for="option in options" :value="option.v">{{ option.t }}</option>
      </select>
    `,
  computed: {
    selected () {
      return this.value
    }
  },
  methods: {
    onChange () {
      this.$emit('input', this.$refs.select.value)
    },
    isSelected (option) {
      return option.v === this.value
    }
  }
})

/**
   * This component generates a label, button and input field for a contact attribute.
   *
   * For example, specify 'first_name' as the field, give it a contact and we render the correct component.
   */
Vue.component('contact-merge-field', {
  props: ['field', 'contact', 'showLabel', 'showSelectButton', 'disabled'],
  template: `
      <div class="form-group">
        <label v-if="showLabel" class="control-label col-md-4"> {{ field.txt }} </label>
        <button v-if="showSelectButton"
                type="button"
                class="btn btn-outline btn-success btn-xs col-md-2"
                @click="useValue"
                style="margin-top: 7px;">
          <i class="fa fa-angle-double-left "></i>
        </button>
        <div class="controls" :class="inputSize">
          <component :is="currentComponent"
                     v-model="value"
                     :disabled="disabled"
                     :options="field.options">
          </component>
        </div>
      </div>
    `,
  components: { 'iam-city-picker': CityPicker, 'iam-date-picker': DatePicker },
  computed: {
    inputSize () {
      if (this.showLabel) {
        return 'col-md-8'
      } else if (this.showSelectButton) {
        return 'col-md-10'
      }
      return 'col-md-12'
    },
    fieldValue () {
      return this.value[this.field.val]
    }
  },
  data () {
    return {
      currentComponent: '',
      value: null
    }
  },
  methods: {
    useValue () {
      this.$emit('use-value', this.field.val, this.value)
    }
  },
  watch: {
    contact (value) {
      this.value = value?.[this.field?.val]
    }
  },
  created () {
    if (this.field.type === 'city_picker') {
      this.currentComponent = 'iam-city-picker'
    } else if (this.field.type === 'select') {
      this.currentComponent = 'contact-merge-select'
    } else if (this.field.type === 'date') {
      this.currentComponent = 'iam-date-picker'
    } else {
      this.currentComponent = 'contact-merge-textinput'
    }
    this.value = this.contact[this.field.val]
  }
})

export default {
  name: 'ContactMergeModal',
  components: { Modal },
  props: {
    headerText: {
      type: String,
      required: false
    },
    automaticPopUp: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      fields: [],
      contacts: [],
      errorCode: null
    }
  },
  computed: {
    sizeClass () {
      switch (this.contacts.slice(1).length) {
        case 2:
          return 'col-md-4'
        case 3:
          return 'col-md-3'
        default:
          return 'col-md-12'
      }
    },
    firstContact () {
      return JSON.parse(JSON.stringify(this.contacts[0]))
    },
    trueContacts () {
      return this.contacts.slice(1)
    },
    errorMessage () {
      if (!this.errorCode) return ''
      const errorMap = {
        CANNOT_MERGE_LESS_THAN_TWO_CONTACTS: 'Er zijn minstens twee contacten vereist.',
        CANNOT_MERGE_MORE_THAN_THREE_CONTACTS: 'Er kunnen maximum drie contacten worden samengevoegd.',
        CANNOT_MERGE_CONTACTS_WITH_DIFFERENT_TYPES: 'Contacten met een verschillend type kunnen niet worden samengevoegd.',
        CANNOT_MERGE_CONTACTS_WITH_SAME_EXTERNAL_REFERENCE: 'De contacten zijn gelinkt aan een externe bron (OpenRental, Organimmo ...) en kunnen niet worden samengevoegd.',
        CANNOT_MERGE_CONTACTS_WITH_INVOICE_RECIPIENT_GROUPINGS: 'De contacten zijn onderdeel van een facturatiecontact en kunnen niet worden samengevoegd'
      }
      return errorMap[this.errorCode]
    }
  },
  methods: {
    async init (contact_ids) {
      try {
        this.errorCode = null
        this.contacts = []
        const response = await getMergeContactsInitialData({ contact_ids: contact_ids })
        this.contacts = [response.data[0], ...response.data]
        this.$refs.modal.show()
        return response
      } catch (error) {
        this.errorCode = error.response?.data?.code
      } finally {
        this.$refs.modal.show()
      }
    },
    async doContactMerge () {
      try {
        const result = await questionModal('Wil je deze contacten samenvoegen? Dit kan niet ongedaan gemaakt worden.')
        if (!result.value) return
        startLoadingModal('Bezig met samenvoegen van de geselecteerde contacten.')
        const contactsToMerge = []
        this.trueContacts.forEach(contact => {
          if (contact.id !== this.firstContact.id) {
            contactsToMerge.push(contact.id)
          }
        })
        const mergeData = {
          merge_into: this.firstContact.id,
          contacts_to_merge: contactsToMerge
        }
        this.fields.forEach(field => {
          mergeData[field.val] = this.$refs[field.val][0].value
        })

        mergeData.birth_place = mergeData.birth_place?.id || null
        mergeData.city = mergeData.city?.id || null

        const response = await mergeContacts(mergeData)
        this.hideModal()
        await successModal('De contacten zijn succesvol samengevoegd.')
        this.$emit('contacts-merged', response.data?.id)
        return response
      } catch (error) {
        console.error(error)
        const defaultMessage = 'Kan contacten niet samenvoegen, gelieve opnieuw te proberen.'
        const errorMessages = {
          USER_TO_MERGE_INTO_HAS_USER_ACCOUNT_EMAIL_ADDRESS_CLEARED: 'Het contact dat wordt behouden, heeft een geactiveerd klantenportaal. Het is niet mogelijk om het e-mailadres te wissen.',
          DUPLICATE_USER_EMAIL: 'Het e-mailadres is gewijzigd, maar er bestaat al een ander contact met een klantenportaal dat gebruikt maakt van dit e-mailadres. Het is niet mogelijk om de contacten samen te voegen.'
        }
        await errorModal(errorMessages[error.response?.data?.code] || defaultMessage)
      }

      $('#app-overlay').addClass('hidden')
    },
    hideModal () {
      this.$emit('contactsMergeCancelled')
      this.$refs.modal.hide()
    },
    useValue (fieldName, fieldValue) {
      this.$refs[fieldName][0].value = fieldValue
    }
  },
  created () {
    const genders = [
      { v: 'M', t: 'Man' },
      { v: 'F', t: 'Vrouw' },
      { v: 'U', t: 'Onbekend' }
    ]
    const types = [
      { v: 'B', t: 'Bedrijf' },
      { v: 'P', t: 'Persoon' }
    ]
    const languages = [
      { v: 'nl', t: 'Nederlands' },
      { v: 'fr', t: 'Frans' },
      { v: 'en', t: 'Engels' }
    ]
    const maritalStatus = [
      { v: 0, t: 'Onbekend' },
      { v: 1, t: 'Gehuwd' },
      { v: 2, t: 'Ongehuwd' },
      { v: 4, t: 'Weduwe/weduwnaar' },
      { v: 5, t: 'Gescheiden' },
      { v: 6, t: 'Wettelijk samenwonend' }
    ]
    this.fields = [
      { val: 'first_name', txt: 'Voornaam' },
      { val: 'last_name', txt: 'Naam' },
      { val: 'company_name', txt: 'Bedrijfsnaam' },
      { val: 'gender', txt: 'Geslacht', options: genders, type: 'select' },
      { val: 'type', txt: 'Type', options: types, type: 'select' },
      { val: 'street', txt: 'Straat' },
      { val: 'number', txt: 'Nr' },
      { val: 'box', txt: 'Bus' },
      { val: 'city', txt: 'Plaats', type: 'city_picker' },
      { val: 'phone', txt: 'Telefoon' },
      { val: 'mobile', txt: 'Mobile' },
      { val: 'email', txt: 'E-mail' },
      { val: 'email2', txt: 'E-mail 2' },
      { val: 'email3', txt: 'E-mail 3' },
      { val: 'language', txt: 'Voorkeur taal', options: languages, type: 'select' },
      { val: 'national_registry_number', txt: 'Rijksregisternr' },
      { val: 'birth_date', txt: 'Geboortedatum', type: 'date' },
      { val: 'birth_place', txt: 'Geboorteplaats', type: 'city_picker' },
      { val: 'marital_status', txt: 'Burgerlijke staat', options: maritalStatus, type: 'select' },
      { val: 'bank_account_number', txt: 'Rekeningnummer' },
      { val: 'vat', txt: 'Ondernemingsnummer' }
    ]
  }
}
</script>
